import React from "react";
import { Col } from "react-bootstrap";
import style from "../css/AdminCard.module.css";

const AdminCard = (props) => {
  const { title, value } = props;
  return (
    <Col className={style.card}>
      <h5>{title}</h5>
      <h3 className="text-end">{value}</h3>
    </Col>
  );
};

export default AdminCard;
