import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAdminSettings } from '../../api/api';

const AdminSettingsContext = createContext();


export const AdminProvider = ({ children }) => {

  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;

  const [adminSettings, setAdminSettings] = useState(null);

  useEffect( async () => {
    const res= await getAdminSettings();
    console.log('AdminProvider', res);
    setAdminSettings(res);
  }, []);

  return (
    <AdminSettingsContext.Provider value={{adminSettings, setAdminSettings}}>
      {children}
    </AdminSettingsContext.Provider>
  );
};

export const useAdminSettings = () => useContext(AdminSettingsContext);