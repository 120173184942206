import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGoogleLogin } from "react-google-login";
import GoogleButton from "react-google-button";
import { useAuth } from "./Authorization";
import { refreshTokenSetup } from "./toolbox/RefreshToken";
import Header from "./Header";
import "./toolbox/littleton.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Card } from "react-bootstrap";
import LoadingSpinner from "./toolbox/LoadingSpinner";
import Logo from "./Logo";
import { useAdminSettings } from "./toolbox/provider/AdminProvider";

function AdminLogin() {
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();
  const {adminSettings} = useAdminSettings();
  const adminyear = adminSettings ? adminSettings.adminYear : null;
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);

  // TODO see if you can do this in down stream
  const { from } = location.state || { from: { pathname: "/admin" } };
  const login = (googleProfile) => {
    history.replace(from);
    auth.signin(googleProfile);
  };
  // FIXME this is run twice when page loads
  //console.log('LOGIN', login);
  const onSuccess = async (googleRes) => {
    // TODO  We want to use this information to log a user into our own back-end,
    //  so the next step is to send the ID token to our own API:
    // console.log(googleRes.tokenId);
    setIsLoading(true);
    const serverRes = await axios.post(`${baseURL}/googleAuth`, {
      tokenId: googleRes.tokenId,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    });

    //console.log('serverRes:', serverRes);
    if (serverRes.data) {
      //console.log('Login Success: googleRes:', googleRes);
      // toast.error(
      //   `Logged in successfully welcome ${googleProfile.name} \nSee //console for full profile object.`,
      // );
      const userData = serverRes.data;
      userData.imageUrl = googleRes.profileObj.imageUrl;
      login(userData);
      refreshTokenSetup(googleRes);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error("Failed to login. Please check with admin.");
      history.push("/login");
    }
    // setIsLoading(false);
  };

  const onFailure = (res) => {
    setIsLoading(false);
    // console.log("Login failed: res:", res);
    toast.error("Failed to login. Please check with admin.");
  };
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    cookiePolicy: "single_host_origin",
    isSignedIn: true,
    accessType: "offline",
    // responseType: 'code',
    // prompt: 'consent',
  });
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <Logo adminyear={adminyear}/>
          <Row className="justify-content-md-center p-4">
            <Col lg="5" md="7" sm="8" xs="12">
              <Card className="text-center" style={{ margin: "auto" }}>
                <Card.Header as="h5"> Admin Login</Card.Header>
                <Card.Body>
                  <p>
                    Please login with your school email address to access
                    transportation enrollment Admin Panel.
                  </p>
                  <Row className="justify-content-md-center mt-3">
                    <GoogleButton onClick={signIn} type="dark" />
                  </Row>
                  <ToastContainer />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
export default AdminLogin;
