import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminSearch from "./AdminSearch";
import AdminSettings from "./AdminSettings";
import AdminAddressUpload from "./AdminAddressUpload";
import AdminDashboard from "./AdminDashboard";
import AdminStudentEntry from "./AdminStudentEntry";
import LogoutHooks from "./LogoutHooks";
import { useAuth } from "./Authorization";
import "./toolbox/littleton.css";
import AdminNavbar from "./AdminNavbar";
import AdminSchoolUpload from "./AdminSchoolUpload";
import AdminSettngsPanel from "./AdminSettngsPanel";

const AdminPanel = () => {
  const auth = useAuth();
  return (
    <Router>
      <AdminNavbar />
      <Switch>
        <Route path="/admin" exact component={AdminDashboard} />
        <Route path="/admin/search" component={AdminSearch} />
        <Route path="/admin/settings" component={AdminSettngsPanel} />
        <Route path="/admin/studentEntry" component={AdminStudentEntry} />
      </Switch>
    </Router>
  );
};
export default AdminPanel;
