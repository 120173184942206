import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Modal } from "react-bootstrap";
import FormGroup from "./toolbox/FormGroup";
import axios from "axios";
import AddressBox from "./toolbox/AddressBox";
import style from "./css/AdminSchoolUpload.module.css";
import { toast, ToastContainer } from "react-toastify";

const AdminSchoolUpload = (props) => {
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const [address, setAddress] = useState([]);
  const [mainAddress, setMainAddress] = useState("");
  const [longName, setLongName] = useState("");
  const [shortName, setShortName] = useState("");
  const [number, setNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [district, setDistrict] = useState("");
  const [location, setLocation] = useState({});
  let allData;

  useEffect(() => {
    async function fetchData() {
      // HOW MANY TIMES IS THIS CALL MADE???
      const res = await axios.get(`${baseURL}/addresses`);
      allData = res.data;
      setAddress(allData);
    }
    fetchData();
  }, []);

  const locationHandler = (e) => {
    setLocation((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
    // console.log("location", location);
  };

  const addSchool = async () => {
    let { state, city, zip } = location;
    let data = {
      address: mainAddress,
      schoolLongName: longName,
      schoolShortName: shortName,
      phoneNumber: number,
      website,
      district,
      state,
      city,
      zipCode: zip,
    };
    // console.log("body", data);
    const res = await axios.post(`${baseURL}/school`, data);
    // console.log("School uploaded", res.data);
    toast.success("School added successfully.");
    props.onHide();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className={style.heading}>
        Add school
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <ToastContainer />
            <Col>
              <form className={style.column}>
                <Row>
                  <Col>
                    <Col>
                      <FormGroup
                        label="School name (Long)"
                        type="text"
                        onChange={(e) => setLongName(e.target.value)}
                      />
                    </Col>
                  </Col>
                  <Col>
                    <Col>
                      <FormGroup
                        label="School name (Short)"
                        type="text"
                        onChange={(e) => setShortName(e.target.value)}
                      />
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Col>
                      <FormGroup
                        label="Phone Number"
                        type="text"
                        onChange={(e) => setNumber(e.target.value)}
                      />
                    </Col>
                  </Col>
                  <Col>
                    <Col>
                      <FormGroup
                        label="Website"
                        type="text"
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </Col>
                  </Col>
                  <Col>
                    <Col>
                      <FormGroup
                        label="District"
                        type="text"
                        onChange={(e) => setDistrict(e.target.value)}
                      />
                    </Col>
                  </Col>
                </Row>
                <AddressBox
                  className="no_style"
                  onChange={(add) => setMainAddress(add)}
                  onLocationChange={locationHandler}
                />
              </form>
              <Button onClick={addSchool} className="my-2">
                Add School
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AdminSchoolUpload;
