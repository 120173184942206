import React, { useEffect, useState } from "react";
import { Button, Form, Row, Card, Col, Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { enrollmentStatus, locality, paymentType } from "../data/Data";
import Student from "./toolbox/Student";
import ParentBox from "./toolbox/ParentBox";
import AddressBox from "./toolbox/AddressBox";
import Dropdown from "./toolbox/Dropdown";
import LoadingSpinner from "./toolbox/LoadingSpinner";
import { getAdminSettings } from "./api/api";
// import OuterDiv from "./toolbox/OuterDiv";
import style from "./css/AdminStudentEntry.module.css";

function AdminStudentEntry() {
  // FIXME adminYear must be fetched from settings. Find a way to share the state.
  const [inputs, setInputs] = useState({
    adminYear: "2021",
    registrationStatus: "REGISTERED",
  });
  const [isStudentValidated, setIsStudentValidated] = useState(false);
  const [isParentValidated, setIsParentValidated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [paymentDisabled, setPaymentDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [addressInfo, setAddressInfo] = useState({
    city: locality.city,
    state: locality.state,
    zip: locality.zipCode,
  });
  const [validated, setValidated] = useState(false);

  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;

  let history = useHistory();

  useEffect(async () => {
    // inputs state is updated with address info
    const adminSettings = await getAdminSettings();
    setInputs((current) => ({
      ...current,
      ...addressInfo,
      adminYear: adminSettings.adminYear,
    }));
    setIsDisabled(isParentValidated && isStudentValidated);
  }, [addressInfo, isParentValidated, isStudentValidated]);

  const adddate = (eventCounter, date) => {
    const student = inputs;
    student.birthDate = date;
    setInputs(student);
  };

  const handleSubmit = async (event) => {
    if (event) {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        if (event.target.id === "adminStudentEntry") {
          try {
            setIsLoading(true);
            const response = await axios.post(`${baseURL}/distanceAndStatus/`, [
              inputs,
            ]);
            response.data[0].enrollmentStatus = inputs.enrollmentStatus;
            const res = await axios.post(`${baseURL}/submit`, response.data[0]);
            res.data &&
              setTimeout(() => {
                setIsLoading(false);
                history.push("/admin/settings");
                history.push("/admin/studentEntry");
                toast.success("Student added successfully.");
              }, 100);
          } catch (err) {
            console.log(err);
          }
        }
      }
      setValidated(true);
    }
  };
  const studentValidated = (val) => {
    setIsStudentValidated(val);
  };

  const parentValidated = (val) => {
    setIsParentValidated(val);
  };

  const handleInputChange = (event) => {
    if (event.target.id === "enrollmentStatus") {
      event.target.value === "paid"
        ? setPaymentDisabled(false)
        : setPaymentDisabled(true);
    }
    // console.log('EVENT input:', event);
    setInputs((previous) => ({
      ...previous,
      [event.target.id]: event.target.value,
    }));
  };
  const handleAddressInfoChange = (address) => {
    setAddressInfo((previous) => ({ ...previous, address }));
    // console.log(address);
  };
  // TODO save button should be disabled until all fields are entered
  // TODO add paymentType if paid add options ==> Check, Cash, Money Order

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div style={{ marginTop: "110px" }}>
          <Container className="my-3">
            <Form
              noValidate
              validated={validated}
              id="adminStudentEntry"
              onSubmit={handleSubmit}
              className="form"
            >
              <Student
                counter={0}
                onChange={handleInputChange}
                adddate={adddate}
                studentValidated={studentValidated}
              />

              <Col
                className={`d-flex justify-content-between flex-column flex-sm-row flex-nowrap m-auto ${style.row}`}
              >
                <Col>
                  <Dropdown
                    id="enrollmentStatus"
                    onChange={handleInputChange}
                    label="Enrollment Status"
                    options={enrollmentStatus}
                    defaultVal="Select Status"
                  />
                </Col>
                <Col>
                  <Dropdown
                    id="paymentType"
                    onChange={handleInputChange}
                    disabled={paymentDisabled}
                    label="Payment Type"
                    options={paymentType.filter(
                      (type) => type.label !== "Unibank"
                    )}
                    defaultVal="Select Payment"
                  />
                </Col>
              </Col>
              <AddressBox
                addressInfo={addressInfo}
                className={style.addressBox}
                onChange={handleAddressInfoChange}
              />
              <ParentBox
                parentInfo={inputs}
                onChange={handleInputChange}
                parentValidated={parentValidated}
              />
              <Button
                as="input"
                className="w-100"
                type="submit"
                value="Save"
                disabled={!isDisabled}
              />
            </Form>
            <ToastContainer />
          </Container>
        </div>
      )}
    </>
  );
}
export default AdminStudentEntry;
