import React, { useState } from "react";
import { Button, Form, Col, Table, Container } from "react-bootstrap";
import { CSVLink } from "react-csv";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function AdminAddressUpload() {
  const [file, setFile] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;

  // FIXME if you browse for a file but not select it and click somewhere else, app crashes
  const uploadFile = (e) => {
    e.preventDefault();
    if (file.size > 0) {
      const data = new FormData();
      data.append("file", file);
      const res = axios.post(`${baseURL}/uploadAddresses`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      toast.promise(res, {
        pending: "Uploading address...",
        success: "Address uploaded successfully.",
        error: "Address uploaded failed. Please try again!",
      });
      //console.log(res);
      setFile();
    }
  };
  const fileHandler = (e) => {
    setFile(e.target.files[0]);
    setIsDisabled(false);
  };
  const data = [
    {
      Number: "1",
      Street: "HARWOOD AVENUE",
      "Shaker Lane School": "2.5",
      "Russell Street School": "2.1",
      "Littleton Middle School": "1.8",
    },
    {
      Number: "2",
      Street: "ARC PLACE    ",
      "Shaker Lane School": "1.8",
      "Russell Street School": "4.7",
      "Littleton Middle School": "4.5",
    },
    {
      Number: "3",
      Street: "CRANE ROAD",
      "Shaker Lane School": "4.5",
      "Russell Street School": "3.6",
      "Littleton Middle School": "3.3",
    },
  ];
  return (
    <Container style={{ marginTop: "110px" }}>
      <ToastContainer />
      <form onSubmit={uploadFile} encType="multipart/form-data">
        <Form.Row>
          <Form.Group as={Col} controlId="uploadFile">
            <Form.Text id="passwordHelpBlock" muted>
              You can browse or drag and drop an excel or csv file to upload
              address data
            </Form.Text>
            <Form.File
              className="col-md-6"
              id="filePath"
              label={file?.name || ""}
              onChange={fileHandler}
              custom
            />
          </Form.Group>
        </Form.Row>
        <Button
          as="input"
          type="submit"
          value="Upload File"
          disabled={isDisabled}
        />
      </form>
      <br />
      <br />
      <strong>SAMPLE EXCEL FILE FORMAT</strong>
      <Table id="adminSearch" striped bordered hover>
        <thead>
          <tr>
            <th key="number">Number</th>
            <th key="address">Street</th>
            <th key="SLH">Shaker Lane School</th>
            <th key="RSS">Russell Street School</th>
            <th key="LMS">Littleton Middle School</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td key="0">1</td>
            <td key="1">ABENAKI TRAIL</td>
            <td key="2">4.8</td>
            <td key="3">3.1</td>
            <td key="4">2.9</td>
          </tr>
        </tbody>
      </Table>
      <CSVLink
        data={data}
        filename="my-file.csv"
        className="btn btn-primary m-1"
      >
        Download as CSV
      </CSVLink>
    </Container>
  );
}
export default AdminAddressUpload;
