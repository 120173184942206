import React, { useCallback, useState } from "react";
import { CloseButton, Col, Row } from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import style from "./css/SiblingDetails.module.css";
import { useEffect } from "react";

const SiblingDetails = (props) => {
  const { sibling, counter, studentData, onDelete, edit } = props;
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const [school, setSchool] = useState("");

  useEffect(async () => {
    const res = await axios.get(`${baseURL}/schools`);
    res.data.forEach((element) => {
      if (element.schoolShortName === sibling.school) {
        setSchool(element.schoolLongName);
      }
    });
  }, [sibling]);

  const removeSibling = useCallback(() => {
    studentData.splice(counter, 1);
    onDelete();
  }, [studentData]);

  const editHandler = () => {
    edit(counter);
  };

  return (
    <div className="mb-4">
      <div className="d-flex justify-content-between align-items-center">
        <div className={`p-2 ${style.title}`}>
          {`Sibling ${counter} - ${sibling.fname}'s Information`}
        </div>
        <span className={style.iconBox}>
          <EditIcon
            className={style.icons}
            onClick={editHandler}
            fontSize="medium"
            titleAccess="Edit"
          />
          <DeleteIcon
            onClick={removeSibling}
            className={style.icons}
            fontSize="medium"
            titleAccess="Delete"
          />
        </span>
      </div>
      <Col className={style.info}>
        <Row className={`d-flex justify-content-between ${style.row}`}>
          <Col>First Name : {sibling.fname}</Col>
          <Col>Middle Name : {sibling.mname}</Col>
          <Col>Last Name : {sibling.lname}</Col>
        </Row>
        <Row className={`d-flex justify-content-between ${style.row}`}>
          <Col>Date of Birth : {sibling.birthDate}</Col>
          <Col>School : {school}</Col>
          <Col>Grade : {sibling.grade}</Col>
        </Row>
      </Col>
    </div>
  );
};

export default SiblingDetails;
