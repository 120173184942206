import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Col, Row } from "react-bootstrap";
import Header from "./Header";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Logo from "./Logo";
import { getAdminSettings } from "./api/api";
import { useAdminSettings } from "./toolbox/provider/AdminProvider";

function FreeReg(props) {
  const history = useHistory();
  const {adminSettings} = useAdminSettings();
  // const [adminYear, setAdminYear] = useState("");
  const adminYear = adminSettings ? adminSettings.adminYear : null;

  // useEffect(async () => {
  //   await getAdminSettings().then((res) => {
  //     setAdminYear(res.adminYear);
  //     // console.log(res.adminYear);
  //   });
  // }, []);

  return (
    <div>
      <Logo adminyear={adminYear}/>
      <Header adminYear={adminYear} notification={false} success={true} />
      <Container>
        <Col xs lg="8" className="m-auto text-center">
          <h1 style={{ fontSize: "50px" }}>Registration Successful</h1>
          <div className="mb-3">
            <CheckCircleIcon
              fontSize="large"
              style={{ fontSize: "200px", color: "#4267B2" }}
            />
          </div>
          <h5 className="text-justify">
            Thank you for registering your children for the upcoming school year
            bus transportation. Bus Passes will be mailed directly to your home
            in late August before school starts. Bus Routes will be available
            online in late August. If you have any questions, please email{" "}
            <a href="mailto: busregistration@littletonps.org">
              busregistration@littletonps.org
            </a>
          </h5>
          <Button
            variant="primary"
            className="my-4"
            style={{ fontSize: "20px" }}
            onClick={() => history.push("/")}
          >
            Go to Home page
          </Button>
        </Col>
      </Container>
    </div>
  );
}

export default FreeReg;
