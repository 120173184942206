import React, { useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getAdminSettings } from '../api/api';
import axios from 'axios';
import Dropdown from './Dropdown';
import { schools, grades } from '../../data/Data';
import FormGroup from './FormGroup';
import ValidationError from './ValidationError';
import TextField from '@mui/material/TextField';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import style from '../css/Student.module.css';

const Student = (props) => {
  const {
    counter,
    onChange,
    hasDOB,
    onSchoolChange,
    studentValidated,
    adddate
  } = props;

  const baseURL = window.location.host.includes('localhost')
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;

  //           ---------Minimum Date Limit-----------

  const minDate = new Date(
    new Date().getFullYear() - 22,
    new Date().getMonth(),
    new Date().getDate()
  );
  // .toISOString()
  // .split("T")[0];

  //           ---------Maximum Date Limit-----------

  const maxDate = new Date(
    new Date().getFullYear() - 4,
    new Date().getMonth(),
    new Date().getDate()
  );
  // .toISOString()
  // .split("T")[0];

  const [adminYear, setAdminYear] = useState('');
  const [gradeOptions, setGradeOptions] = useState([]);
  const [fNameValidated, setFNameValidated] = useState(true);
  const [mNameValidated, setMNameValidated] = useState(true);
  const [lNameValidated, setLNameValidated] = useState(true);
  const [dateValidated, setDateValidated] = useState(true);
  const [date, setDate] = useState('');
  const [schoolList, setSchoolList] = useState([]);
  const [grade, setGrade] = useState('');
  const [datePickerValue, setdatePickerValue] = useState('');
  let adminSettings;

  //          ---------- Datepicker Handler ------------------

  const changeHandler = (d) => {
    if (d) {
      let year = d.getFullYear();
      let month =
        d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let day = d.getDate() >= 10 ? d.getDate() : '0' + d.getDate();
      let finalDate = `${year}-${month}-${day}`;
      setDate(finalDate);
      const tempDate = new Date(finalDate);
      tempDate.setMinutes(tempDate.getMinutes() + tempDate.getTimezoneOffset());
      setdatePickerValue(tempDate);
      adddate(counter, finalDate);
    }
  };

  useEffect(async () => {
    const res = await axios.get(`${baseURL}/schools`);
    setSchoolList(res.data);
    // console.log('res', res.data);
  }, []);

  useEffect(async () => {
    await getAdminSettings().then((res) => {
      adminSettings = res;
      setAdminYear(
        `${+adminSettings?.adminYear}-${+adminSettings?.adminYear + 1}`
      );
    });
    if (fNameValidated && mNameValidated && lNameValidated && dateValidated) {
      studentValidated(true);
    } else {
      studentValidated(false);
    }
  }, [fNameValidated, mNameValidated, lNameValidated, dateValidated]);

  const fNameValidate = (e) => {
    const nameRegEx = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$/;
    e.target.value.length > 0
      ? nameRegEx.test(e.target.value)
        ? setFNameValidated(true)
        : setFNameValidated(false)
      : setFNameValidated(true);
  };
  const mNameValidate = (e) => {
    const nameRegEx = /^[A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$/;
    e.target.value.length > 0
      ? nameRegEx.test(e.target.value)
        ? setMNameValidated(true)
        : setMNameValidated(false)
      : setMNameValidated(true);
  };
  const lNameValidate = (e) => {
    const nameRegEx = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$/;
    e.target.value.length > 0
      ? nameRegEx.test(e.target.value)
        ? setLNameValidated(true)
        : setLNameValidated(false)
      : setLNameValidated(true);
  };

  const dateValidate = (d) => {
    if (d) {
      let year = d.getFullYear();
      let month =
        d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1);
      let day = d.getDate() >= 10 ? d.getDate() : '0' + d.getDate();
      let finalDate = `${year}-${month}-${day}`;
      new Date(finalDate) >= minDate && new Date(finalDate) <= maxDate
        ? setDateValidated(true)
        : setDateValidated(false);
    }
  };

  // FIXME there is a bug when you do back and forth between school and grade dropdowns
  const handleSchoolDropdown = (event) => {
    const newGradeOptions = grades.filter(
      (g) => g.level === event.target.value
    );
    setGradeOptions(newGradeOptions);
    onChange(event);
    onSchoolChange && onSchoolChange();
  };

  const gradeHandler = (event) => {
    setGrade(event.target.value);
    onChange(event);
  };
  return (
    <div>
      <div className={`p-2 ${style.title}`}>
        {counter != 0 ? 'Sibling ' + counter + ' - ' : ''}Student Information
      </div>
      <div className={`p-3  ${style.student} `}>
        <Form.Row
          counter={counter}
          className={`justify-content-between flex-nowrap m-auto ${style.row}`}
        >
          <Col counter={counter}>
            <FormGroup
              id="fname"
              type="text"
              onChange={onChange}
              label="First Name"
              placeholder=""
              onBlur={fNameValidate}
              required={true}
            />
            {!fNameValidated && <ValidationError field="First Name" />}
          </Col>
          <Col counter={counter}>
            <FormGroup
              id="mname"
              type="text"
              onChange={onChange}
              label="Middle Name (optional)"
              required={false}
              onBlur={mNameValidate}
            />
            {!mNameValidated && <ValidationError field="Middle Name" />}
          </Col>

          <Col counter={counter}>
            <FormGroup
              id="lname"
              type="text"
              onChange={onChange}
              label="Last Name"
              placeholder=""
              onBlur={lNameValidate}
            />
            {!lNameValidated && <ValidationError field="Last Name" />}
          </Col>
        </Form.Row>
        <Form.Row
          counter={counter}
          className={`justify-content-between flex-nowrap m-auto ${style.row}`}
        >
          {hasDOB ? (
            <Col counter={counter}>
              <Col>
                <Form.Label>Date of birth</Form.Label>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    id="birthDate"
                    placeholder="MM/DD/YYYY"
                    inputFormat="MM/dd/yyyy"
                    minDate={new Date(minDate)}
                    maxDate={new Date(maxDate)}
                    defaultCalendarMonth={new Date(maxDate)}
                    // onError={() => setDateValidated(false)}
                    // onAccept={() => setDateValidated(true)}
                    value={datePickerValue}
                    onChange={changeHandler}
                    onBlur={dateValidate}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        className={style.datePicker}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                {/* {!dateValidated && <ValidationError field="Date" />} */}
              </Col>
            </Col>
          ) : (
            <></>
          )}
          <Col counter={counter}>
            <Dropdown
              id="school"
              // value={school}
              onChange={handleSchoolDropdown}
              label={`School (${adminYear})`}
              defaultVal="School"
              options={schoolList}
            />
          </Col>
          <Col counter={counter}>
            <Dropdown
              id="grade"
              onChange={gradeHandler}
              label={`Grade (${adminYear})`}
              value={grade}
              defaultVal="Grade"
              options={gradeOptions}
            />
          </Col>
        </Form.Row>
      </div>
    </div>
  );
};
Student.defaultProps = {
  hasDOB: true
};
Student.propTypes = {
  counter: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  hasDOB: PropTypes.bool
};
export default Student;
