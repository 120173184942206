import axios from "axios";

const getAdminSettings = async () => {
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  try {
    const res = await axios.get(`${baseURL}/adminSettings`);
    const currentData = res.data.filter((obj) => obj.activeInd === "Y");
    const adminSettings = currentData[0];
    return adminSettings;
  } catch (err) {
    console.log(err);
    return "";
  }
};

export { getAdminSettings };
