import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import AdminSchoolUpload from "./AdminSchoolUpload";
import axios from "axios";
import constructTable from "./toolbox/ConstructTable";

const AdminSchools = () => {
  const [showModal, setShowModal] = useState(false);
  const [schools, setSchools] = useState([]);
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;

  useEffect(async () => {
    const res = await axios.get(`${baseURL}/schools`);
    // console.log("schools", res.data);
    setSchools(res.data);
  }, [showModal]);

  const headers = {
    schoolLongName: "School",
    address: "Address",
    phoneNumber: "Phone Number",
  };
  const data = {
    headers,
    options: schools,
  };
  return (
    <div style={{ marginTop: "110px" }}>
      <div className="d-flex justify-content-end">
        <Button className="mb-4" onClick={() => setShowModal(true)}>
          Add School
        </Button>
      </div>
      <AdminSchoolUpload show={showModal} onHide={() => setShowModal(false)} />
      {constructTable(data)}
    </div>
  );
};

export default AdminSchools;
