import React from "react";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import styles from "../css/FormGroup.module.css";

const FormGroup = (props) => {
  const {
    id,
    onChange,
    label,
    type,
    placeholder,
    disabled,
    required,
    onBlur,
    value,
    min,
    max,
  } = props;
  return (
    <Form.Group as={Col} className={styles.formGroup}>
      <Form.Label className={styles.label}>{label}</Form.Label>
      <Form.Control
        id={id}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        required={required}
        className={styles.input}
        onBlur={onBlur}
        min={min}
        max={max}
      />
    </Form.Group>
  );
};
export default FormGroup;
