import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Button, Col, Row, Alert } from "react-bootstrap";
import axios from "axios";
import Header from "./Header";
import Logo from "./Logo";
import constructTable from "./toolbox/ConstructTable";
import PropTypes from "prop-types";
import { UnipayXmlTest, UnipayXmlProd } from "../data/UnipayXML";
import UnipayForm from "./toolbox/payment/UnipayForm";
import { getAdminSettings } from "./api/api";
import LoadingSpinner from "./toolbox/LoadingSpinner";

// FIXME - When you refresh the page all student
// data comes back including the free student data.
// Update free student State after submitting the data.
function PaidReg(props) {
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const { location } = props;
  let total = 0;
  const [cartTotal, setCartTotal] = useState(0);
  const history = useHistory();
  const [pageBody, setPageBody] = useState();
  const [adminYear, setAdminYear] = useState("");
  const [busFee, setBusFee] = useState(0);
  const [maxFee, setMaxFee] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [payStatus, setPayStatus] = useState("");
  // TODO is there a case where location is null/undefined?
  const studentData = location.state;
  const paid = studentData?.filter((st) => st.enrollmentStatus === "paid");
  const [free, setFree] = useState(
    studentData?.filter((st) => st.enrollmentStatus === "free")
  );
  const [paymentButton, setPaymentButton] = useState(true);
  const headers = {
    fname: "First Name",
    lname: "Last Name",
    grade: "Grade",
    due: "Due",
    enrollmentStatus: "Status",
    distanceFromSchool: "Distance",
    school: "School",
    registrationStatus: "Registration Status",
  };

  const freeData =
    free.length > 0
      ? {
          headers,
          options: free,
          id: "freeStudents",
        }
      : "";

  const paidData =
    paid.length > 0
      ? {
          headers,
          options: paid,
          id: "paidStudents",
        }
      : "";

  // TODO how is this possible?
  if (!location.state) {
    history.push("/");
    return <div />;
  }

  const calculateFee = () => {
    return new Promise((resolve, reject) => {
      studentData.forEach((element) => {
        if (element.enrollmentStatus === "free") {
          element.due = "$0";
        } else if (element.enrollmentStatus === "paid") {
          if (total < maxFee) {
            element.due = `$${busFee}`;
            total += busFee;
            setCartTotal(total);
          } else {
            element.due = "$0";
          }
        }
      });
      resolve();
    });
  };

  let adminSettings;
  useEffect(async () => {
    await getAdminSettings()
      .then((res) => {
        adminSettings = res;
        setAdminYear(adminSettings.adminYear);
        setBusFee(adminSettings.busFee);
        setMaxFee(adminSettings.busFeeFamilyMax);
      })
      .then(
        calculateFee().then(() => {
          if (paidData && !freeData) {
            // console.log('paid', paid);
            setPageBody(
              <Container className="mt-2" fluid="sm">
                {constructTable(paidData)}
                <h4 className="text-center">
                  Total amount: <b>${cartTotal}</b>
                </h4>
                <Row className="justify-content-md-center">
                  <Col className="text-center text-danger">
                    <p>
                      The above listed student/s are eligible for school bus
                      transportation. <br />
                      <b>Please click Proceed to Payment Button </b> below to
                      pay the registration fee. Students are{" "}
                      <b>
                        <u>NOT </u>
                      </b>
                      registered until you complete the payment. Once you click
                      the <b>"Proceed to Payment"</b> button, you will be
                      redirected to Unibank to complete the payment and
                      registration.
                    </p>
                  </Col>
                </Row>
              </Container>
            );
          } else {
            setPageBody(<div />);
          }
          if (freeData && paidData) {
            const data = {
              headers,
              options: [...free, ...paid],
            };
            register();
            setPageBody(
              <Container className="mt-2" fluid="sm">
                {constructTable(data)}
                <h4 className="text-center">
                  Total amount: <b>${cartTotal}</b>
                </h4>
                <Row className="justify-content-md-center">
                  <Col className="text-center text-danger">
                    <p>
                      The above listed student/s are eligible for school bus
                      transportation. <br />
                      <b>Please click Proceed to Payment Button </b> below to
                      pay the registration fee. Students are{" "}
                      <b>
                        <u>NOT </u>
                      </b>
                      registered until you complete the payment. Once you click
                      the <b>"Proceed to Payment"</b> button, you will be
                      redirected to Unibank to complete the payment and
                      registration.
                    </p>
                  </Col>
                </Row>
              </Container>
            );
          }
        })
      );
    setIsLoading(false);
  }, [isLoading, studentData, busFee, maxFee]);

  //TODO refactor this code to remove unnessary api call
  const register = async () => {
    try {
      //const res = await axios.put(`${baseURL}/enrollment/`, free);
      setPaymentButton(false);
    } catch (err) {
      console.log(err);
    }
  };

  // let xmlData = process.env.NODE_ENV === 'development' ?
  // UnipayXmlTest(paid, cartTotal) : UnipayXmlProd(paid, cartTotal);

  let xmlData;
  let unipayUrl;
  const hostLabel = window.location.host;
  if (hostLabel.includes("littleton")) {
    xmlData = UnipayXmlProd(paid, cartTotal);
    unipayUrl = process.env.REACT_APP_UNIPAY_URL;
  } else {
    xmlData = UnipayXmlTest(paid, cartTotal);
    unipayUrl = process.env.REACT_APP_UNIPAY_TEST_URL;
  }

  // let xmlData = UnipayXmlProd(paid, cartTotal);

  // TODO - https://stackoverflow.com/questions/168455/how-do-you-post-to-an-iframe
  // TODO - Embed unibank site into iframe

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Logo adminyear={adminYear}/>
          <Header adminYear={adminYear} notification={false} />
          {pageBody}
          {paidData ? (
            <UnipayForm
              xmlData={xmlData}
              freeData={freeData}
              paymentButton={paymentButton}
              url={unipayUrl}
            />
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
}
PaidReg.propTypes = {
  location: PropTypes.object.isRequired,
};
export default PaidReg;
