import React, { useState, useEffect } from "react";
import Logo from "./Logo";
import axios from "axios";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
import LoadingSpinner from "./toolbox/LoadingSpinner";

const PaymentStatus = () => {
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  // const uniqueId = "e30ec688-65e1-426b-b81e-cf45f103f843"; // REGISTERED
  // const uniqueId = "d1d34bdb-077c-4761-8e4f-09a01635cac0";   // IN PROGRESS
  const uniqueId = sessionStorage.getItem("uniqueId");
  const [registrationStatus, setRegistrationStatus] = useState("");
  const [data, setData] = useState([{}]);
  const [isLoading, setIsLoading] = useState(true);
  const {adminSettings} = useAdminSettings();
  const adminYear = adminSettings ? adminSettings.adminYear : null;

  useEffect(async () => {
    const res = await axios.get(`${baseURL}/student/uniqueId/${uniqueId}`);
    setData(res.data);
    setRegistrationStatus(res.data[0].registrationStatus);
    setIsLoading(false);
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Logo adminyear={adminYear}/>
          {registrationStatus === "REGISTERED" ? (
            <PaymentSuccess data={data} />
          ) : (
            <PaymentFailed />
          )}
        </>
      )}
    </div>
  );
};

export default PaymentStatus;
