import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import constructTable from "./toolbox/ConstructTable";
import { Container } from "react-bootstrap";

const PaymentSuccess = ({ data }) => {
  const headers = {
    fname: "First Name",
    lname: "Last Name",
    grade: "Grade",
    // due: "Amount",
    // enrollmentStatus: "Status",
    distanceFromSchool: "Distance",
    school: "School",
    registrationStatus: "Registration Status",
  };
  const tableData = {
    options: data,
    headers,
  };
  return (
    <Container className="m-auto text-center">
        <div className="mt-4">{constructTable(tableData)}</div>
      <div>
        <CheckCircleIcon
          fontSize="large"
          style={{ fontSize: "150px", color: "#4267B2" }}
        />
      </div>
      <h1 className="mt-4">Thank You</h1>
      <h2 className="my-4">Your payment is successful.</h2>
      <Link to="/">
        <Button>Go to homepage</Button>
      </Link>
    </Container>
  );
};

export default PaymentSuccess;
