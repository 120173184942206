import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import AdminCard from "./toolbox/AdminCard";
import { PieGraph } from "./toolbox/PieGraph";
import style from "./css/AdminDashboard.module.css";

const AdminDashboard = () => {
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const [busFee, setBusFee] = useState("");
  const [maxBusFee, setMaxBusFee] = useState("");
  const [adminYear, setAdminYear] = useState("");
  const [regStatus, setRegStatus] = useState("");
  const [registrationData, setRegistrationData] = useState([{ value: "" }]);
  const [enrollmentData, setEnrollmentData] = useState([{}]);

  useEffect(async () => {
    const res = await axios.get(`${baseURL}/adminSettings`);
    const adminInfo = res?.data.filter((ln) => ln.activeInd === "Y")[0];
    adminInfo.busFee === null
      ? setBusFee("$0")
      : setBusFee(`$${adminInfo.busFee}`);

    adminInfo.busFeeFamilyMax === null
      ? setMaxBusFee("$0")
      : setMaxBusFee(`$${adminInfo.busFeeFamilyMax}`);

    setAdminYear(`${adminInfo.adminYear}-${+adminInfo.adminYear + 1}`);
    setRegStatus(adminInfo.regStatus);

    const free = await axios.get(
      `${baseURL}/student/enrollmentCount/free/${adminInfo.adminYear}`
    );
    const paid = await axios.get(
      `${baseURL}/student/enrollmentCount/paid/${adminInfo.adminYear}`
    );
    setEnrollmentData([
      { name: "Paid", value: paid.data },
      { name: "Free", value: free.data },
    ]);
    const inProgress = await axios.get(
      `${baseURL}/student/registerCount/IN_PROGRESS/${adminInfo.adminYear}`
    );
    const registered = await axios.get(
      `${baseURL}/student/registerCount/REGISTERED/${adminInfo.adminYear}`
    );
    setRegistrationData([
      { name: "In Progress", value: inProgress.data },
      { name: "Registered", value: registered.data },
    ]);
  }, []);

  return (
    <Container fluid style={{ backgroundColor: "#f3f4fa", marginTop: "87px" }}>
      <Row className={`flex-sm-row flex-column ${style.mainRow}`}>
        <Col className="my-2">
          <AdminCard title="School Year" value={adminYear} />
        </Col>
        <Col className="my-2">
          <AdminCard title="Registration Status" value={regStatus} />
        </Col>
        <Col className="my-2">
          <AdminCard
            title="Total Students"
            value={registrationData[0]?.value + registrationData[1]?.value}
          />
        </Col>
        <Col className="my-2">
          <AdminCard title="Bus Fee" value={busFee} />
        </Col>
        <Col className="my-2">
          <AdminCard title="Max Bus Fee" value={maxBusFee} />
        </Col>
      </Row>
      <Row className={style.mainRow}>
        <Col>
          <Col className={`${style.col}`}>
            <h3>Registration Status</h3>
            <Row
              className={`flex-sm-row flex-column align-items-center ${style.row}`}
            >
              <Col>
                <h5>In Progress</h5>
                <h3>{registrationData[0]?.value}</h3>
              </Col>
              <Col>
                <h5>Registered</h5>
                <h3>{registrationData[1]?.value}</h3>
              </Col>
              <Col>
                <PieGraph data={registrationData} />
              </Col>
            </Row>
          </Col>
        </Col>
        <Col>
          <Col className={`${style.col}`}>
            <h3>Enrollment Status</h3>
            <Row
              className={`flex-sm-row flex-column align-items-center ${style.row}`}
            >
              <Col>
                <h5>Paid</h5>
                <h3>{enrollmentData[0]?.value}</h3>
              </Col>
              <Col>
                <h5>Free</h5>
                <h3>{enrollmentData[1]?.value}</h3>
              </Col>
              <Col>
                <PieGraph data={enrollmentData} />
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
