import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormGroup from './FormGroup';
import ValidationError from './ValidationError';
import NumberFormat from 'react-number-format';
import style from '../css/ParentBox.module.css';

const ParentBox = (props) => {
  const { parentInfo, onChange, data, parentValidated } = props;
  const [emailValidated, setEmailValidated] = useState(true);
  const [numberValidated, setNumberValidated] = useState(true);
  const [nameValidated, setNameValidated] = useState(true);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (emailValidated && nameValidated) {
      parentValidated(true);
    } else {
      parentValidated(false);
    }
  }, [emailValidated, nameValidated]);

  const nameValidate = (e) => {
    const nameRegEx = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$/;
    nameRegEx.test(e.target.value)
      ? setNameValidated(true)
      : setNameValidated(false);
  };

  const emailValidate = (e) => {
    const emailRegEx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    emailRegEx.test(e.target.value)
      ? setEmailValidated(true)
      : setEmailValidated(false);
  };

  return (
    <div className={style.parent}>
      <div
        className="p-2"
        style={{
          width: 'max-content',
          backgroundColor: '#00a568',
          color: 'white',
          fontSize: '20px',
        }}
      >
        Parent Information
      </div>
      <div
        className="p-3"
        style={{
          border: '1px solid #00a568',
        }}
      >
        <div>
          <Form.Row
            className={`justify-content-between flex-nowrap ${style.row}`}
          >
            <Col>
              <FormGroup
                id="parentName"
                type="text"
                // value={parentInfo?.parentName}
                onChange={onChange}
                label="Full Name"
                required
                onBlur={nameValidate}
              />
              {!nameValidated && <ValidationError field="Full Name" />}
            </Col>
            <Col>
              <FormGroup
                id="parentEmailAddress"
                type="email"
                // value={parentInfo?.parentEmailAddress}
                onChange={onChange}
                label="Email Address"
                required
                onBlur={emailValidate}
              />
              {!emailValidated && <ValidationError field="Email" />}
            </Col>

            {/* TODO add phone number check and format it properly
          https://www.npmjs.com/package/react-number-format
          */}
            <Col>
              <Col className="form-group">
                <label htmlFor="parentPhoneNumber">Phone Number</label>
                <NumberFormat
                  id="parentPhoneNumber"
                  className={`form-control ${style.input}`}
                  // value={parentInfo.parentPhoneNumber}
                  thousandSeparator={true}
                  format="(###) ###-####"
                  onChange={onChange}
                  required
                />
              </Col>
              {!numberValidated && <ValidationError field="Phone Number" />}
            </Col>
          </Form.Row>
        </div>
      </div>
    </div>
  );
};
// ParentBox.propTypes = {
//   parentInfo: PropTypes.object.isRequired,
//   // onChange: PropTypes.func.isRequired,
// };
export default ParentBox;
