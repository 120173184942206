import React from "react";
import logo from "../data/Littleton/logo.png";
import { Row, Col, Image } from "react-bootstrap";

const Logo = ({adminyear}) => {
  //next year equals to number value of admin year +1
  const nextYear = Number(adminyear) + 1;
  console.log('adminyear', adminyear);
  const schoolYear = adminyear ? `School Year: ${adminyear}-${nextYear}` : '';
  return (
    <div>
      <Row className="littleton-head-bg m-0">
        <Col xs={6} md={4}>
          <Image src={logo} rounded className="logo" />
        </Col>
        <Col md={{ span: 4, offset: 4 }} className="">
          <h5 className="header text-white">{schoolYear}</h5>
        </Col>
      </Row>
    </div>
  );
};

export default Logo;
