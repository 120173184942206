import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import closed from "../closed.png";
import NotificationBox from "./toolbox/NotificationBox";
import { getAdminSettings } from "./api/api";

const RegistrationClosed = () => {
  const [closeMessage, setCloseMessage] = useState("");
  useEffect(async () => {
    const res = await getAdminSettings();
    setCloseMessage(res.closedMessage);
  }, []);

  return (
    <Container className="d-flex flex-column align-items-center ">
      <NotificationBox>{closeMessage}</NotificationBox>
      <NotificationBox>
        <p className="text-justify">
          If you have any query, please email us at
          busregistration@littletonps.org. Thank you for using our new automated
          registration system.
        </p>
      </NotificationBox>

      <img src={closed} alt="Registration closed" width="100%" />
    </Container>
  );
};

export default RegistrationClosed;
