import React, { useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const PaymentFailed = () => {
  return (
    <Col className="text-center m-auto">
      <h1 style={{ fontSize: "50px" }}>Uh-oh!</h1>
      <h2>Your payment is failed.</h2>
      <div>
        <CancelIcon
          fontSize="large"
          style={{ fontSize: "200px", color: "#CC0000" }}
        />
      </div>
      <Link to="/">
        <Button>Go to homepage</Button>
      </Link>
    </Col>
  );
};

export default PaymentFailed;
