import React from "react";
import { Nav, Navbar, Image, Container } from "react-bootstrap";
import LogoutHooks from "./LogoutHooks";
import flowlystLogo from "../data/flowlyst.png";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

const AdminNavbar = () => {
  return (
    <div>
      <Navbar
        className="littleton-head-bg"
        expand="lg"
        variant="dark"
        fixed="top"
        collapseOnSelect
      >
        <Container fluid>
          <Navbar.Brand className="img-container">
            <Image src={flowlystLogo} rounded className="logo-small mr-2" />
            Littleton Admin
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto justify-content-center">
              <LinkContainer exact to="/admin">
                <Nav.Link>Dashboard</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/admin/search">
                <Nav.Link>Search</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/admin/settings">
                <Nav.Link>Settings</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/admin/studentEntry">
                <Nav.Link>Student Entry</Nav.Link>
              </LinkContainer>
            </Nav>
            <LogoutHooks />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default AdminNavbar;
