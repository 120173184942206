import React from "react";
import { Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdminAddressUpload from "./AdminAddressUpload";
import AdminSchools from "./AdminSchools";
import AdminSchoolUpload from "./AdminSchoolUpload";
import AdminSettings from "./AdminSettings";
import AdminSettings_Sidebar from "./AdminSettings_Sidebar";

const AdminSettngsPanel = () => {
  return (
    <Router>
      <Row className="m-0 p-0">
        <Col xs={12} md={3} lg={2} className="m-0 p-0">
          <AdminSettings_Sidebar />
        </Col>
        <Col>
          <Switch>
            <Route exact path="/admin/settings" component={AdminSettings} />
            <Route
              path="/admin/addressUpload"
              component={AdminAddressUpload}
            />
            <Route
              path="/admin/schoolUpload"
              component={AdminSchools}
            />
          </Switch>
        </Col>
      </Row>
    </Router>
  );
};

export default AdminSettngsPanel;
