import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { locality } from "../data/Data";
import Header from "./Header";
import Student from "./toolbox/Student";
import ParentBox from "./toolbox/ParentBox";
import AddressBox from "./toolbox/AddressBox";
import { bigSample } from "../data/BigSample";
import PropTypes, { number } from "prop-types";
import { getAdminSettings } from "./api/api";
import AddSibling from "./AddSibling";
import SiblingDetails from "./SiblingDetails";
import OuterDiv from "./toolbox/OuterDiv";
import Logo from "./Logo";
import InnerDiv from "./toolbox/InnerDiv";
import ReviewInfo from "./ReviewInfo";
import { ToastContainer, toast } from "react-toastify";
import LoadingSpinner from "./toolbox/LoadingSpinner";
import RegistrationClosed from "./RegistrationClosed";
import style from "./css/RegistrationForm.module.css";

function RegistrationForm(props) {
  const { location } = props;

  // TODO find a way to add adminYear right here. It is undefined in the beginning ???
  const [studentData, setStudentData] = useState([{}]);
  const [addressInfo, setAddressInfo] = useState({
    city: locality.city,
    state: locality.state,
    zip: locality.zipCode,
  });
  const [parentInfo, setParentInfo] = useState({});
  const [counter, setCounter] = useState(0);
  const [index, setIndex] = useState(0);
  const [adminYear, setAdminYear] = useState("");
  const [schoolYear, setSchoolYear] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [regStatus, setRegStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isStudentValidated, setIsStudentValidated] = useState(false);
  const [isParentValidated, setIsParentValidated] = useState(false);
  const [busFee, setBusFee] = useState(0);

  const history = useHistory();
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  let adminSettings;

  useEffect(async () => {
    await getAdminSettings().then((res) => {
      adminSettings = res;
      setAdminYear(adminSettings.adminYear);
      setRegStatus(adminSettings.regStatus);
      setBusFee(adminSettings.busFee);
      setIsLoading(false);
      setSchoolYear(
        `${+adminSettings.adminYear}-${+adminSettings.adminYear + 1}`
      );
    });
    // studentData state is updated with address and parent info
    setStudentData((current) =>
      current.map((student) => ({
        ...student,
        ...addressInfo,
        ...parentInfo,
      }))
    );
    setIsDisabled(!(isParentValidated && isStudentValidated));
    if (location.state) {
      setStudentData(location.state);
    }
  }, [
    addressInfo,
    parentInfo,
    reviewData,
    isStudentValidated,
    isParentValidated,
  ]);

  // logs free sample data for easy entry
  const showFreeSample = () => {
    const st = bigSample.filter((s) => s.enrollmentStatus === "free");
    const index = parseInt(Math.random() * st.length);
  };
  // logs paid sample data for easy entry
  const showPaidSample = () => {
    const st = bigSample.filter((s) => s.enrollmentStatus === "paid");
    const index = parseInt(Math.random() * st.length);
  };

  const calculateFee = (data) => {
    data.forEach((element) => {
      if (element.enrollmentStatus === "free") {
        element.due = "$0";
      } else if (element.enrollmentStatus === "paid") {
        element.due = `$${busFee}`;
      }
    });
  };

  const schoolFullName = (array) => {
    return new Promise((resolve, reject) => {
      array.forEach(async (student, index) => {
        const res = await axios.get(`${baseURL}/schools`);
        // console.log('index', index);
        res.data.forEach((element) => {
          if (element.schoolShortName === student.school) {
            student["fullSchoolName"] = element.schoolLongName;
            resolve();
            return array;
          }
        });
      });
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity()) {
      try {
        const res = await axios.post(
          `${baseURL}/distanceAndStatus/`,
          studentData
        );
        calculateFee(res.data);
        const result = await schoolFullName(res.data);

        setReviewData(res.data);
        setShowReview(true);
        // console.log('review data', reviewData);
        // console.log('result', result);
      } catch (err) {
        console.log(err);
        const message =
          "Please check all fields. If you are still experiencing problems registering, " +
          "please contact school business office.";
        toast.error(message);
      }
    }
  };

  const studentValidated = (val) => {
    setIsStudentValidated(val);
  };

  const parentValidated = (val) => {
    setIsParentValidated(val);
  };

  const handleInputChange = (event) => {
    const eventCounter = parseInt(
      event.target.parentElement.parentElement.getAttribute("counter")
    );
    // TODO remove if this value can be populated in the beginning
    if (!studentData[0].adminYear) {
      studentData[0].adminYear = adminYear;
    }
    const allStudents = [...studentData];
    const tempStudent = {
      ...studentData[eventCounter],
      [event.target.id]: event.target.value,
    };
    allStudents[eventCounter] = tempStudent;

    setStudentData(() => allStudents);
  };
  const adddate = (eventCounter, date) => {
    if (!studentData[0].adminYear) {
      studentData[0].adminYear = adminYear;
    }
    const allStudents = [...studentData];
    const tempStudent = {
      ...studentData[eventCounter],
      birthDate: date,
    };
    allStudents[eventCounter] = tempStudent;
    setStudentData(() => allStudents);
  };

  const addSibling = () => {
    setModalShow(true);
    setCounter((prev) => prev + 1);
  };

  const handleAddressInfoChange = (address) => {
    setAddressInfo((previous) => ({ ...previous, address }));
    // studentData[counter].address = address;
    studentData.forEach((student) => {
      student.address = address;
    });
  };
  const handleParentInfoChange = (event) => {
    setParentInfo((previous) => ({
      ...previous,
      [event.target.id]: event.target.value,
    }));
  };

  const hideModal = () => {
    setModalShow(false);
    if (editing) setEditing(false);
  };

  const closeModal = () => {
    setModalShow(false);
    if (counter > 0) {
      studentData.splice(counter, 1);
      setCounter((prev) => prev - 1);
    }
    if (editing) setEditing(false);
  };

  const editSibling = (index) => {
    setEditing(true);
    setModalShow(true);
    setIndex(index);
  };
  const doneEditing = () => setEditing(false);
  // FIXME must add a unique key for all iterated elements
  // TODO save button should be disabled until all fields are entered
  // isLoading ? <LoadingSpinner />
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Logo adminyear={adminYear} />
          {regStatus !== "Closed" ? (
            <OuterDiv>
              <InnerDiv>
                <Container className={`${style.container} px-0`}>
                  <Header adminYear={adminYear} />
                  <Form id="registrationForm">
                    <div className={style.studentInfo}>
                      <Student
                        counter={0}
                        onChange={handleInputChange}
                        adddate={adddate}
                        className="py-3"
                        studentValidated={studentValidated}
                      />

                      <AddressBox
                        addressInfo={addressInfo}
                        data={studentData}
                        onChange={handleAddressInfoChange}
                        className={style.addressBox}
                        disabled={true}
                      />
                    </div>
                    <ParentBox
                      parentInfo={parentInfo}
                      data={studentData}
                      onChange={handleParentInfoChange}
                      className="py-3"
                      parentValidated={parentValidated}
                    />
                    {studentData.slice(1).map((student, index) => (
                      <div key={index}>
                        <SiblingDetails
                          sibling={student}
                          studentData={studentData}
                          counter={index + 1}
                          onDelete={() => setCounter((prev) => prev - 1)}
                          edit={() => editSibling(index + 1)}
                        />
                      </div>
                    ))}
                    <AddSibling
                      counter={editing ? index : counter}
                      show={modalShow}
                      editing={editing}
                      addressinfo={addressInfo}
                      parentinfo={parentInfo}
                      adddate={adddate}
                      adminyear={adminYear}
                      data={studentData}
                      onHide={editing ? hideModal : closeModal}
                      doneEditing={doneEditing}
                      onClose={hideModal}
                      change={handleInputChange}
                    />
                    {showReview && (
                      <ReviewInfo
                        show={showReview}
                        onHide={() => setShowReview(false)}
                        data={reviewData.length > 0 ? reviewData : studentData}
                        studentdata={studentData}
                        busfee={busFee}
                      />
                    )}
                    <ToastContainer />
                    <Form.Row className="justify-content-between text-center">
                      <Col>
                        <Button
                          disabled={studentData.length > 5}
                          as="input"
                          value="Add Sibling"
                          type="button"
                          onClick={addSibling}
                        />
                      </Col>
                      <Col>
                        <Button
                          as="input"
                          value="Continue"
                          type="button"
                          onClick={handleSubmit}
                          className={style.button}
                          disabled={isDisabled}
                        />
                      </Col>
                    </Form.Row>
                  </Form>
                  {process.env.NODE_ENV === "development" ? (
                    <Row className="my-3 mx-0 p-2 alert-danger justify-content-between align-items-center">
                      <b>DEVELOPMENT MODE</b>
                      <Col>
                        <Button
                          as="input"
                          value="Show Free Sample"
                          type="button"
                          onClick={showFreeSample}
                          className={style.button}
                        />
                      </Col>
                      <Col>
                        <Button
                          as="input"
                          value="Show Paid Sample"
                          type="button"
                          onClick={showPaidSample}
                          className={style.button}
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                </Container>
              </InnerDiv>
            </OuterDiv>
          ) : (
            <RegistrationClosed />
          )}
        </>
      )}
    </>
  );
}
RegistrationForm.propTypes = {
  adminYear: PropTypes.string,
};
export default RegistrationForm;
