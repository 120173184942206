import React, { useState, useEffect } from "react";
import axios from "axios";
// import { Typeahead } from "react-bootstrap-typeahead";
import { Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import FormGroup from "./FormGroup";
import TextField from "@mui/material/TextField";
// import Stack from '@mui/material/Stack';
import Autocomplete from "@mui/material/Autocomplete";
import style from "../css/AddressBox.module.css";
import ValidationError from "./ValidationError";
// import "react-bootstrap-typeahead/css/Typeahead.css";

const AddressBox = (props) => {
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const { addressInfo, onChange, data, disabled, className, onLocationChange } =
    props;
  const [address, setAddress] = useState([]);
  const [addressValidated, setAddressValidated] = useState(true);
  let allData;

  // let stored_address = "";
  let stored_city = "";
  let stored_state = "";
  let stored_zip = "";

  useEffect(() => {
    async function fetchData() {
      // HOW MANY TIMES IS THIS CALL MADE???
      const res = await axios.get(`${baseURL}/addresses`);
      allData = res.data;
      setAddress(allData);
    }
    fetchData();
  }, []);

  const validateAddress = (event) => {
    if (!address.find((item) => item.address === event.target.value)) {
      setAddressValidated(false);
    } else {
      setAddressValidated(true);
    }
  };
  return (
    <Col className={className}>
      <Col>
        <Form.Group>
          <Form.Label>Address</Form.Label>
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            aria-required={true}
            options={address.map((option) => option.address)}
            onChange={(e, add) => onChange(add)}
            onBlur={validateAddress}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select your address"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
                required
              />
            )}
          />
        </Form.Group>
      </Col>
      {!addressValidated && <ValidationError field="Address" />}
      <Form.Row className="justify-content-between flex-column flex-sm-row">
        <Col>
          <FormGroup
            id="city"
            value={stored_city || addressInfo?.city}
            label="City"
            onChange={onLocationChange}
            placeholder={addressInfo?.city}
            disabled={disabled}
          />
        </Col>
        <Col>
          <FormGroup
            id="state"
            value={stored_state || addressInfo?.state}
            onChange={onLocationChange}
            label="State"
            placeholder={addressInfo?.state}
            disabled={disabled}
          />
        </Col>
        <Col>
          <FormGroup
            id="zip"
            type="text"
            onChange={onLocationChange}
            value={stored_zip || addressInfo?.zip}
            label="Zip"
            placeholder={addressInfo?.zip}
            disabled={disabled}
          />
        </Col>
      </Form.Row>
    </Col>
  );
};

export default AddressBox;
