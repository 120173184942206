import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import style from '../css/Dropdown.module.css';

const Dropdown = (props) => {
  const {
    id,
    options,
    onChange,
    value,
    required,
    defaultVal,
    disabled,
    label
  } = props;
  // console.log('getRegistrationYear:', value);
  // console.log('DROPDOWN component is loaded', value);
  // FIXME look into defaultValue in options
  return (
    <Form.Group className={style.dropdown}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="select"
        id={id}
        required={required}
        onChange={(e) => onChange(e)}
        className={style.select}
        disabled={disabled}
        value={value}
      >
        {defaultVal ? <option value="">{defaultVal}</option> : ''}
        {options
          ? options.map((o) => (
              <option
                key={o.label || o.id}
                defaultValue={value === o.label || value === o.schoolLongName}
                value={o.value || o.schoolShortName}
              >
                {o.label || o.schoolLongName}
              </option>
            ))
          : ''}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        Please choose a value.
      </Form.Control.Feedback>
    </Form.Group>
  );
};

Dropdown.defaultProps = {
  required: true,
  disabled: false
};
Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  defaultVal: PropTypes.string,
  disabled: PropTypes.bool
};
export default Dropdown;
