import React, { useEffect, useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { CSVLink } from 'react-csv';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { headers, locality, enrollmentStatus, paymentType } from '../data/Data';
import { getAdminSettings } from './api/api';
import Student from './toolbox/Student';
import constructTable from './toolbox/ConstructTable';
import Dropdown from './toolbox/Dropdown';
import style from './css/AdminSearch.module.css';
import InnerDiv from './toolbox/InnerDiv';
import { changeDate } from './toolbox/utils/utils';

function AdminSearch() {
  // TODO how does it work if students have registrations for multiple years
  // do we keep adding to student_info or create student_info_2022 ...
  // FIXME cannot search with address and/or other fields
  const baseURL = window.location.host.includes('localhost')
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const [paymentDisabled, setPaymentDisabled] = useState(true);

  const [addressInfo, setAddressInfo] = useState({
    city: locality.city,
    state: locality.state,
    zip: locality.zipCode
  });
  const [inputs, setInputs] = useState({});
  const [table, setTable] = useState();
  const [schoolYear, setSchoolYear] = useState('');
  const [adminYear, setAdminYear] = useState('');
  const [isStudentValidated, setIsStudentValidated] = useState(false);
  const [adminSearchData, setAdminSearchData] = useState([{}]);
  const [allData, setAllData] = useState('');
  const [confMessageVariant, setConfMessageVariant] = useState('');
  const [confMessage, setConfMessage] = useState('');

  const data = {
    id: 'adminSearch',
    headers: {
      fname: 'First Name',
      lname: 'Last Name',
      grade: 'Grade',
      enrollmentStatus: 'Program Type',
      registrationStatus: 'Registration Status',
      distanceFromSchool: 'Distance',
      address: 'Address',
      school: 'School',
      emailSentDate: 'Email Sent Date'
    },
    options: ''
  };
  let adminSettings;

  useEffect(async () => {
    await getAdminSettings().then((res) => {
      adminSettings = res;
      setAdminYear(adminSettings.adminYear);
      setSchoolYear(
        `${+adminSettings?.adminYear}-${+adminSettings?.adminYear + 1}`
      );
    });
    // inputs state is updated with address info
    setInputs((current) => ({
      ...current,
      ...addressInfo
    }));
  }, [addressInfo]);

  const handlePDFdownload = () => {
    const doc = new JsPDF();
    autoTable(doc, { html: '#adminSearch' });
    doc.save('adminSearch.pdf');
  };
  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
      if (event.target.id === 'adminForm') {
        try {
          const res = await axios.post(`${baseURL}/studentSearch`, inputs);
          data.options = res.data.filter((ln) => ln.adminYear === adminYear);
          data.options.map((st) => {
            st.emailSentDate = st.emailSentDate
              ? changeDate(st.emailSentDate)
              : null;
          });
          setTable(constructTable(data, 'adminSearch'));
          setAdminSearchData(data.options);
          setAllData(res.data);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };
  const onSchoolChange = () => {
    setInputs((prev) => ({
      ...prev,
      grade: null
    }));
  };
  const handleInputChange = (event) => {
    if (event.target.id === 'enrollmentStatus') {
      event.target.value === 'paid'
        ? setPaymentDisabled(false)
        : setPaymentDisabled(true);
    }
    setInputs((previous) => ({
      ...previous,
      [event.target.id]: event.target.value
    }));
  };
  const studentValidated = (val) => {
    setIsStudentValidated(val);
  };

  const handleSendEmail = async () => {
    try {
      const res = await axios.post(
        `${baseURL}/send-reminder-email/${adminYear}`,
        {
          data: adminSearchData,
          year: adminYear
        }
      );
      const failed = res.data.list_of_failed_emails;
      if (failed !== '') {
        setConfMessageVariant('danger');
        setConfMessage(`The following emails failed to send: ${failed}`);
      } else {
        setConfMessageVariant('success');
        setConfMessage('Emails sent successfully!');
      }
    } catch (err) {
      setConfMessageVariant('danger');
      setConfMessage('Failed to send emails!');
      console.log(err);
    }
  };

  const handleClearMessage = () => {
    setConfMessage('');
  };

  return (
    <div style={{ marginTop: '110px' }} id="admin-search">
      <InnerDiv>
        <Form
          noValidate
          id="adminForm"
          onSubmit={handleSubmit}
          className="my-4"
        >
          <Student
            counter={0}
            onChange={handleInputChange}
            hasDOB={false}
            schoolYear={schoolYear}
            onSchoolChange={onSchoolChange}
            studentValidated={studentValidated}
          />
          <Col
            className={`d-flex justify-content-between flex-nowrap m-auto ${style.row}`}
          >
            <Col style={{ padding: '0px 5px' }}>
              <Dropdown
                id="enrollmentStatus"
                onChange={handleInputChange}
                label="Enrollment Status"
                options={enrollmentStatus}
                defaultVal="Select Status"
              />
            </Col>
            <Col style={{ padding: '0px 5px' }}>
              <Dropdown
                id="paymentType"
                onChange={handleInputChange}
                disabled={paymentDisabled}
                label="Payment Type"
                options={paymentType}
                defaultVal="Select Payment"
              />
            </Col>
          </Col>
          <div className="my-2 d-flex flex-column flex-sm-row">
            <Button as="input" className="m-1" type="submit" value="Search" />
            <Button
              as="input"
              onClick={handlePDFdownload}
              className="m-1"
              type="submit"
              value="Download as PDF"
            />
            <CSVLink
              data={adminSearchData}
              headers={headers}
              filename="my-file.csv"
              className="btn btn-primary m-1"
            >
              Download as CSV
            </CSVLink>
            <CSVLink
              data={allData}
              headers={headers}
              filename="my-file.csv"
              className="btn btn-primary m-1"
            >
              Download CSV for all years
            </CSVLink>
            <Button
              as="input"
              onClick={handleSendEmail}
              className="m-1"
              type="submit"
              value="Send Email Reminder"
            />
          </div>
        </Form>
        {confMessage && (
          <Alert variant={confMessageVariant}>
            {confMessage !== '' && confMessage}
            <Button
              as="input"
              onClick={handleClearMessage}
              className="float-right"
              type="submit"
              value="OK"
              style={{ padding: '3px 8px', margin: 'auto 0' }}
            />
          </Alert>
        )}
        {table}
      </InnerDiv>
    </div>
  );
}

export default AdminSearch;
