import React from "react";
import { Col, Navbar, Container, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import style from "./css/AdminSettings_Sidebar.module.css";

const AdminSettings_Sidebar = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg="dark"
      variant="dark"
      className={`${style.sidebar}`}
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-center"
      >
        <Nav className="me-auto flex-column">
          <LinkContainer to="/admin/settings">
            <Nav.Link>Settings</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/addressUpload">
            <Nav.Link>Address upload</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/admin/schoolUpload">
            <Nav.Link>Schools</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
      {/* </Col> */}
    </Navbar>
  );
};

export default AdminSettings_Sidebar;
