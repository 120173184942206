import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import Dropdown from "./toolbox/Dropdown";
import { grades } from "../data/Data";
import FormGroup from "./toolbox/FormGroup";
import TextField from "@mui/material/TextField";
import DateAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import ValidationError from "./toolbox/ValidationError";
import style from "./css/AddSibling.module.css";

const AddSibling = (props) => {
  const {
    adminyear: adminYear,
    data,
    parentinfo,
    addressinfo,
    counter,
    onHide,
    onClose,
    editing,
    doneEditing,
  } = props;

  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const schoolYear = `${+adminYear}-${+adminYear + 1}`;

  const [gradeOptions, setGradeOptions] = useState(grades);
  const [fNameValidated, setFNameValidated] = useState(true);
  const [mNameValidated, setMNameValidated] = useState(true);
  const [lNameValidated, setLNameValidated] = useState(true);
  const [isDisabled, setisDisabled] = useState(false);
  const [fname, setFname] = useState(data[counter]?.fname || "");
  const [mname, setMname] = useState(data[counter]?.mname || "");
  const [lname, setLname] = useState(data[counter]?.lname || "");
  const [birthDate, setBirthDate] = useState(data[counter]?.birthDate || "");
  const [school, setSchool] = useState(data[counter]?.school || "");
  const [grade, setGrade] = useState(data[counter]?.grade || "");
  const [date, setDate] = useState("");
  const [schoolList, setSchoolList] = useState([]);

  //           ---------Minimum Date Limit-----------

  const minDate = new Date(
    new Date().getFullYear() - 22,
    new Date().getMonth(),
    new Date().getDate()
  );
  //           ---------Maximum Date Limit-----------

  const maxDate = new Date(
    new Date().getFullYear() - 4,
    new Date().getMonth(),
    new Date().getDate()
  );
  useEffect(async () => {
    const res = await axios.get(`${baseURL}/schools`);
    setSchoolList(res.data);
    //console.log("res", res.data);
  }, []);

  useEffect(() => {
    if (fNameValidated && mNameValidated && lNameValidated) {
      setisDisabled(true);
    } else {
      setisDisabled(false);
    }
    if (editing) {
      setFname(data[counter]?.fname);
      setMname(data[counter]?.mname);
      setLname(data[counter]?.lname);
      setBirthDate(data[counter]?.birthDate);
      setSchool(data[counter]?.school);
      setGrade(data[counter]?.grade);
    } else {
      setFname("");
      setMname("");
      setLname("");
      setSchool("");
      setGrade("");
      setBirthDate("");
    }
  }, [fNameValidated, mNameValidated, lNameValidated, editing]);

  //          ---------- Datepicker Handler ------------------

  const changeHandler = (d) => {
    if (d) {
      let year = d.getFullYear();
      let month =
        d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1);
      let day = d.getDate() >= 10 ? d.getDate() : "0" + d.getDate();
      let finalDate = `${year}-${month}-${day}`;
      setBirthDate(`${year}-${month}-${day}`);
    }
  };

  const handleSchoolDropdown = (event) => {
    const newGradeOptions = grades.filter(
      (g) => g.level === event.target.value
    );
    setGradeOptions(newGradeOptions);
    setSchool(event.target.value);
  };

  const submit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      try {
        let newValues = {
          adminYear,
          ...parentinfo,
          ...addressinfo,
          fname,
          mname,
          lname,
          school,
          grade,
          birthDate,
        };
        data.push(newValues);
        onClose();
        setFname("");
        setMname("");
        setLname("");
        setSchool("");
        setGrade("");
        setBirthDate("");
      } catch {
        toast.error("Error! Re-check the form.");
      }
    }
  };

  const updateHandler = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      try {
        let newValue = {
          ...data[counter],
          fname,
          mname,
          lname,
          school,
          grade,
          birthDate,
        };
        data[counter] = newValue;
        doneEditing();
        onClose();
        setFname("");
        setMname("");
        setLname("");
        setSchool("");
        setGrade("");
        setBirthDate("");
      } catch {
        toast.error("Error! Re-check the form.");
      }
    }
  };

  const fNameValidate = (e) => {
    const nameRegEx = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$/;
    nameRegEx.test(e.target.value)
      ? setFNameValidated(true)
      : setFNameValidated(false);
  };
  const mNameValidate = (e) => {
    const nameRegEx = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$/;

    e.target.value.length > 0
      ? nameRegEx.test(e.target.value)
        ? setMNameValidated(true)
        : setMNameValidated(false)
      : setMNameValidated(true);
  };
  const lNameValidate = (e) => {
    const nameRegEx = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$/;
    nameRegEx.test(e.target.value)
      ? setLNameValidated(true)
      : setLNameValidated(false);
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className={style.title}>
          {counter != 0 ? "Sibling " + counter + " - " : ""}Student Information
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editing ? updateHandler : submit}>
            <Form.Row
              className={`justify-content-between flex-nowrap m-auto ${style.row}`}
            >
              <Col>
                <FormGroup
                  id="fname"
                  type="text"
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  label="First Name"
                  placeholder=""
                  // value={fname}
                  onBlur={fNameValidate}
                  required={true}
                />
                {!fNameValidated && <ValidationError field="First Name" />}
              </Col>
              <Col>
                <FormGroup
                  id="mname"
                  type="text"
                  value={mname}
                  onChange={(e) => setMname(e.target.value)}
                  label="Middle Name (optional)"
                  required={false}
                  onBlur={mNameValidate}
                />
                {!mNameValidated && <ValidationError field="Middle Name" />}
              </Col>
              <Col>
                <FormGroup
                  id="lname"
                  type="text"
                  value={lname}
                  onChange={(e) => setLname(e.target.value)}
                  label="Last Name"
                  placeholder=""
                  onBlur={lNameValidate}
                  required
                />
                {!lNameValidated && <ValidationError field="Last Name" />}
              </Col>
            </Form.Row>
            <Form.Row
              className={`justify-content-between flex-nowrap m-auto ${style.row}`}
            >
              <Col>
                <Form.Label>Date of birth</Form.Label>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    id="birthDate"
                    placeholder="MM/DD/YYYY"
                    inputFormat="MM/dd/yyyy"
                    minDate={new Date(minDate)}
                    maxDate={new Date(maxDate)}
                    defaultCalendarMonth={new Date(maxDate)}
                    // onError={() => setDateValidated(false)}
                    // onAccept={() => setDateValidated(true)}
                    value={new Date(birthDate)}
                    onChange={changeHandler}
                    // onBlur={dateValidate}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        className={style.datePicker}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                {/* {!dateValidated && <ValidationError field="Date" />} */}
              </Col>

              <Col>
                <Dropdown
                  id="school"
                  value={school}
                  onChange={handleSchoolDropdown}
                  label={`School (${schoolYear})`}
                  defaultVal="School"
                  options={schoolList}
                />
              </Col>
              <Col>
                <Dropdown
                  id="grade"
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                  label={"Grade (" + schoolYear + ")"}
                  defaultVal="Grade"
                  options={gradeOptions}
                />
              </Col>
            </Form.Row>
            <ToastContainer />
            <Row className="d-flex justify-content-around mt-4">
              {editing ? (
                <>
                  <Button
                    className={style.button}
                    type="submit"
                    disabled={!isDisabled}
                    // onClick={updateHandler}
                  >
                    Update
                  </Button>
                  <Button className={style.button} onClick={onClose}>
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className={style.button}
                    type="submit"
                    disabled={!isDisabled}
                    // onClick={submit}
                  >
                    Add Sibling
                  </Button>
                  <Button className={style.button} onClick={onHide}>
                    Cancel
                  </Button>
                </>
              )}
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddSibling;
