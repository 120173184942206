import React, { useEffect, useState } from "react";
import { Container, Col, Row, Image, Alert } from "react-bootstrap";
import axios from "axios";
import PropTypes from "prop-types";
import NotificationBox from "./toolbox/NotificationBox";
import "./toolbox/littleton.css";

function Header(props) {
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const { page, notification, success } = props;
  const [helpMessage, setHelpMessage] = useState();
  let regStatus = "";
  let adminSettings;

  useEffect(async () => {
    try {
      const res = await axios.get(`${baseURL}/adminSettings`);
      const currentData = res.data.filter((obj) => obj.activeInd === "Y");
      adminSettings = currentData[0];
      adminSettings.regStatus
        ? (regStatus = adminSettings.regStatus.toLowerCase() + "Message")
        : "";
    } catch (err) {
      console.log(err);
      adminSettings = "";
    }

    if (page === "admin") {
      setHelpMessage(<div />);
    } else if (success) {
      setHelpMessage(<div />);
    } else {
      setHelpMessage(
        <div>
          {adminSettings[regStatus] && (
            <NotificationBox>{adminSettings[regStatus]}</NotificationBox>
          )}
          <NotificationBox>
            If you experience any issues registering your child/ren, please
            email us at{" "}
            <a href="mailto: busregistration@littletonps.org">
              busregistration@littletonps.org
            </a>
            . Thank you for using our new automated registration system.
          </NotificationBox>

          {notification &&
          (adminSettings.notification1Status ||
            adminSettings.notification2Status) ? (
            <>
              {adminSettings.notification1Status ? (
                <NotificationBox>
                  <b>Notice 1 - </b>
                  {adminSettings.notification1}
                  <br />
                </NotificationBox>
              ) : (
                ""
              )}
              {adminSettings.notification2Status ? (
                <NotificationBox>
                  <b>Notice 2 - </b>
                  {adminSettings.notification2}
                </NotificationBox>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </div>
      );
    }
  }, []);

  // TODO populate alert message from adminSettings
  return (
    <Container fluid className="p-0">
      <Row className="my-3 px-2">
        {!success && <h4 className="mx-auto">School Bus Registration Form</h4>}
      </Row>
      {helpMessage}
    </Container>
  );
}

Header.defaultProps = {
  page: "",
  notification: true,
};

Header.propTypes = {
  page: PropTypes.string,
  notification: PropTypes.bool,
};

export default Header;
