import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  Button,
  Col,
  Container,
  InputGroup,
  Row,
  Form,
  FormControl
} from 'react-bootstrap';
import Switch from '@mui/material/Switch';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { schoolYears, registration } from '../data/Data';
import Dropdown from './toolbox/Dropdown';
import FormGroup from './toolbox/FormGroup';
import LoadingSpinner from './toolbox/LoadingSpinner';
import style from './css/AdminSettings.module.css';
import AdminSettings_Sidebar from './AdminSettings_Sidebar';

function AdminSettings() {
  // FIXME initial values do not populate for year and regStatus
  // Bonus: reset to default(saved version from DB)

  const [saveButton, setSaveButton] = useState(true);
  const [checkButtonStatus1, setCheckButtonStatus1] = useState(false);
  const [checkButtonStatus2, setCheckButtonStatus2] = useState(false);
  const [adminSettings, setAdminSettings] = useState({});
  const [adminYear, setAdminYear] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const baseURL = window.location.host.includes('localhost')
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  let currentData;
  const history = useHistory();
  // TODO Write adminsettings to session data and send request in App.js once.
  useEffect(async () => {
    const res = await axios.get(`${baseURL}/adminSettings`);
    currentData = res?.data.filter((obj) => obj.activeInd === 'Y');
    setCheckButtonStatus1(currentData[0]?.notification1Status);
    setCheckButtonStatus2(currentData[0]?.notification2Status);
    setAdminSettings(currentData[0]);

    setAdminYear(
      schoolYears.filter(
        (obj) => obj.adminYear === currentData[0]?.adminYear
      )[0]?.value
    );
    const activeYear = schoolYears.filter(
      (obj) => obj.adminYear === currentData[0]?.adminYear
    );
    if (activeYear[0]?.label.search(/Active/i) === -1) {
      activeYear[0].label += ' (Active)';
    } else {
      const inactiveYear = schoolYears.filter(
        (obj) => obj.adminYear !== currentData[0]?.adminYear
      );
      inactiveYear[0].label = inactiveYear[0]?.label.replace(' (Active)', '');
    }
    const activeStatus = registration.filter(
      (obj) => obj.value === currentData[0]?.regStatus
    );
    // if (activeStatus[0]?.label.search(/Active/i) === -1) {
    //   activeStatus[0].label += " (Active)";
    //   const inactiveStatus = registration.filter(
    //     (obj) => obj.value !== currentData[0]?.regStatus
    //   );
    //   inactiveStatus[0].label = inactiveStatus[0].label.replace(
    //     " (Active)",
    //     ""
    //   );
    // }
    setIsLoading(false);
    // }
    // fetchData();
  }, [schoolYears, registration]);

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
      // TODO check the response and add a message as "successfully saved!"
      try {
        const res = await axios.put(`${baseURL}/updateSettings`, adminSettings);
        if (res) {
          toast.success('Successfully saved!');
        }
      } catch (err) {
        console.log(err);
        toast.error('Error! Please try again.');
      }
    }
  };
  const handleInputChange = (event) => {
    setAdminSettings((previous) => ({
      ...previous,
      [event.target.id]: event.target.value
    }));
    // console.log(adminSettings);
    setSaveButton(false);
  };
  const handleDropdownChange = (event) => {
    handleInputChange(event);
    setSaveButton(false);
  };

  const adminYearHandler = (event) => {
    setAdminYear(
      schoolYears.filter((obj) => obj.value === event.target.value)[0]?.value
    );

    setAdminSettings((previous) => ({
      ...previous,
      adminYear: schoolYears.filter((obj) => obj.value === adminYear)[0]
        ?.adminYear
    }));
    setSaveButton(false);
    // console.log(adminYear);
  };

  // FIXME saveButton's state must be tied to checkButtonStatus
  // when the notification is checked, DB must be updated so next time its state can be populated correctly
  // must be shown on the registration page if checked
  // const handleCheckButton = (event) => {
  //   const boxNumber = event.target.id.indexOf('1')=== -1 ? 1 : 0;
  //   console.log("checkbox event",checkButtonStatus[boxNumber])
  //   setAdminSettings((previous) => ({
  //     ...previous,
  //     [event.target.id]: !checkButtonStatus[boxNumber],
  //   }));
  //   setCheckButtonStatus(checkButtonStatus.map((item, index) => {
  //     index === boxNumber ? !checkButtonStatus[boxNumber] : item
  //   }));
  //   setSaveButton(false);

  // };

  const handleCheckButton1 = (event) => {
    setAdminSettings((previous) => ({
      ...previous,
      [event.target.id]: !checkButtonStatus1
    }));
    setCheckButtonStatus1(!checkButtonStatus1);
    setSaveButton(false);
  };

  const handleCheckButton2 = (event) => {
    setAdminSettings((previous) => ({
      ...previous,
      [event.target.id]: !checkButtonStatus2
    }));
    setCheckButtonStatus2(!checkButtonStatus2);
    setSaveButton(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    handleSubmit(event);
    setSaveButton(true);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={style.container}>
          <Col className={style.div}>
            <Row className="mb-2 flex-column flex-sm-row">
              <Col>
                <Dropdown
                  id="adminYear"
                  onChange={adminYearHandler}
                  label="Bus Registration Year"
                  options={schoolYears}
                  value={adminYear}
                  disabled={true}
                />
              </Col>
              <Col>
                <Dropdown
                  id="regStatus"
                  onChange={handleDropdownChange}
                  label="Registration Status"
                  value={adminSettings?.regStatus}
                  options={registration}
                />
              </Col>
            </Row>
            <Row className="flex-column flex-sm-row">
              <Col>
                <FormGroup
                  id="busFee"
                  type="text"
                  label="Bus Fee"
                  value={adminSettings?.busFee}
                  onChange={handleInputChange}
                />
              </Col>
              <Col>
                <FormGroup
                  id="busFeeFamilyMax"
                  type="text"
                  label="Max Bus Fee"
                  value={adminSettings?.busFeeFamilyMax}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>

            <Row className="flex-column flex-sm-row">
              <Col>
                <Col>
                  <Form.Label>Open Message</Form.Label>
                </Col>
                <Col>
                  <FormControl
                    as="textarea"
                    id="openMessage"
                    onChange={handleInputChange}
                    value={adminSettings?.openMessage}
                    aria-label="With textarea"
                    aria-describedby="inputGroup-sizing-lg"
                    className={style.textBox}
                  />
                </Col>
              </Col>
              <Col>
                <Col>
                  <Form.Label>Closed Message</Form.Label>
                </Col>
                <Col>
                  <FormControl
                    as="textarea"
                    id="closedMessage"
                    onChange={handleInputChange}
                    value={adminSettings?.closedMessage}
                    aria-label="With textarea"
                    aria-describedby="inputGroup-sizing-lg"
                    className={style.textBox}
                  />
                </Col>
              </Col>
            </Row>
            <Row className="my-2 flex-column flex-sm-row">
              <Col>
                <Col>
                  <Form.Label>Notification 1</Form.Label>
                  <Switch
                    id="notification1Status"
                    defaultChecked={adminSettings?.notification1Status}
                    onClick={handleCheckButton1}
                  />
                </Col>
                <InputGroup.Prepend>
                  <div className="d-flex flex-column  w-100">
                    <Col>
                      <FormControl
                        as="textarea"
                        id="notification1"
                        onChange={handleInputChange}
                        value={adminSettings?.notification1}
                        aria-label="With textarea"
                        aria-describedby="inputGroup-sizing-lg"
                        className={style.textBox}
                      />
                    </Col>
                  </div>
                </InputGroup.Prepend>
              </Col>
              <Col>
                <Col>
                  <Form.Label>Notification 2</Form.Label>
                  <Switch
                    id="notification2Status"
                    defaultChecked={adminSettings?.notification2Status}
                    onClick={handleCheckButton2}
                  />
                </Col>
                <InputGroup.Prepend>
                  <div className="d-flex flex-column  w-100">
                    <Col>
                      <FormControl
                        as="textarea"
                        id="notification2"
                        onChange={handleInputChange}
                        value={adminSettings?.notification2}
                        aria-label="With textarea"
                        aria-describedby="inputGroup-sizing-lg"
                        className={style.textBox}
                      />
                    </Col>
                  </div>
                </InputGroup.Prepend>
              </Col>
            </Row>
            <br />
            <Col>
              <Button
                disabled={saveButton}
                size="lg"
                as="input"
                id="adminSettings"
                type="submit"
                value="Save"
                onClick={handleSave}
                className="w-100"
              />
            </Col>
            <ToastContainer />
          </Col>
        </div>
      )}
    </>
  );
}
export default AdminSettings;
