import React from 'react';
import style from "../css/InnerDiv.module.css"

const InnerDiv = (props) => {
    return (
        <div className={style.innerDiv}>
            {props.children}
        </div>
    )
}

export default InnerDiv
