import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import style from "./css/ReviewInfo.module.css";
import { ToastContainer, toast } from "react-toastify";

const ReviewInfo = (props) => {
  let { onHide, data, studentdata, busfee } = props;
  const baseURL = window.location.host.includes("localhost")
    ? process.env.REACT_APP_BASE_URL_LOCAL
    : process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const [totalStudents, setTotalStudents] = useState([]);
  const [unregistered, setUnregistered] = useState([]);
  const [inProgressReg, setInProgressReg] = useState([]);
  const [registeredStudent, setRegisteredStudent] = useState([]);
  const [oldParentInfo, setOldParentInfo] = useState({});
  const [newParentInfo, setNewParentInfo] = useState({});
  const [selectedValue, setSelectedValue] = useState("old");
  const [studentArray, setStudentArray] = useState(data || []);

  const {
    address,
    city,
    parentEmailAddress,
    parentName,
    parentPhoneNumber,
    state,
    zip,
  } = data[0];
  const calculateFee = (element) => {
    if (element.enrollmentStatus === "free") {
      element.due = "$0";
    } else if (element.enrollmentStatus === "paid") {
      element.due = `$${busfee}`;
    }
  };
  const checkStatus = (data) => {
    if (data.registrationStatus === "IN_PROGRESS") {
      setInProgressReg((st) => setInProgressReg([...st, data]));
    } else {
      setRegisteredStudent((st) => setRegisteredStudent([...st, data]));
    }
  };

  // ------------------checking for duplicates----------------------
  useEffect(() => {
    studentArray.forEach(async (student) => {
      const response = await axios.get(
        `${baseURL}/studentSearch?fname=${student.fname}&lname=${student.lname}&birthDate=${student.birthDate}&grade=${student.grade}&adminYear=${student.adminYear}`
      );
      if (response.data.length > 0) {
        response.data[0].fullSchoolName = student.fullSchoolName;
        calculateFee(response.data[0]);
        setTotalStudents((st) => setTotalStudents([...st, response.data[0]]));
        checkStatus(response.data[0]);

        setOldParentInfo({
          parentName: response.data[0].parentName,
          parentEmailAddress: response.data[0].parentEmailAddress,
          parentPhoneNumber: response.data[0].parentPhoneNumber,
          address: response.data[0].address,
          state: response.data[0].state,
          city: response.data[0].city,
          zip: response.data[0].zip,
        });
        setNewParentInfo({
          parentName: studentdata[0].parentName,
          parentEmailAddress: studentdata[0].parentEmailAddress,
          parentPhoneNumber: studentdata[0].parentPhoneNumber,
          address: studentdata[0].address,
          state: studentdata[0].state,
          city: studentdata[0].city,
          zip: studentdata[0].zip,
        });
      } else {
        setUnregistered((st) => setUnregistered([...st, student]));
        setTotalStudents((st) => setTotalStudents([...st, student]));
      }
    });
  }, [studentArray]);

  const redirectToPage = (data) => {
    const free = data.filter((st) => st.enrollmentStatus === "free");
    const paid = data.filter((st) => st.enrollmentStatus === "paid");
    if (free.length > 0 && !paid.length > 0) {
      history.push("/freereg", free);
    } else {
      history.push("/paidreg", data);
    }
  };

  const changeInfo = (status, students) => {
    if (status === "old") {
      students.forEach((student) => {
        student.address = oldParentInfo.address;
        student.state = oldParentInfo.state;
        student.city = oldParentInfo.city;
        student.zip = oldParentInfo.zip;
        student.parentName = oldParentInfo.parentName;
        student.parentPhoneNumber = oldParentInfo.parentPhoneNumber;
        student.parentEmailAddress = oldParentInfo.parentEmailAddress;
      });
    } else if (status === "new") {
      students.forEach((student) => {
        student.address = studentdata[0].address;
        student.state = studentdata[0].state;
        student.city = studentdata[0].city;
        student.zip = studentdata[0].zip;
        student.parentName = studentdata[0].parentName;
        student.parentPhoneNumber = studentdata[0].parentPhoneNumber;
        student.parentEmailAddress = studentdata[0].parentEmailAddress;
      });
    }
  };

  const schoolFullName = (array) => {
    return new Promise((resolve, reject) => {
      array.forEach(async (student, index) => {
        const res = await axios.get(`${baseURL}/schools`);
        res.data.forEach((element) => {
          if (element.schoolShortName === student.school) {
            student["fullSchoolName"] = element.schoolLongName;
            resolve();
            return array;
          }
        });
      });
    });
  };

  const handleChange = async (event) => {
    setSelectedValue(event.target.value);
    changeInfo(event.target.value, totalStudents);
    const res = await axios.post(
      `${baseURL}/distanceAndStatus/`,
      totalStudents
    );
    res.data.forEach((element) => {
      calculateFee(element);
    });
    totalStudents.forEach((element) => {
      res.data.forEach((student) => {
        if (
          element.fname === student.fname &&
          element.lname === student.lname &&
          element.birthDate === student.birthDate &&
          element.grade === student.grade
        ) {
          student.id = element.id;
          student.fullSchoolName = element.fullSchoolName;
        }
      });
    });

    let progress = [];
    let registered = [];
    res.data.forEach((element) => {
      if (element.registrationStatus === "IN_PROGRESS") {
        progress.push(element);
      } else if (element.registrationStatus === "REGISTERED") {
        registered.push(element);
      }
    });
    setInProgressReg(progress);
    setRegisteredStudent(registered);
    setTotalStudents(res.data);
  };

  const submitHandler = async () => {
    try {
      const res = await axios.post(`${baseURL}/pre-enrollment/`, totalStudents);
      redirectToPage(res.data);
    } catch (err) {
      toast.error(
        "All fields are mandatory. Please click edit and fill out all fields."
      );
    }
    // console.log('res', res.data) ;
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <ToastContainer />
      <Modal.Header closeButton className={style.title}>
        Review your information
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <div className={`p-2 ${style.heading}`}>Parent's Information</div>
          <Col className={`${style.info} d-flex`}>
            {oldParentInfo.address && (
              <Col>
                <Row className="align-items-center">
                  <Radio
                    checked={selectedValue === "old"}
                    onChange={handleChange}
                    color="success"
                    value="old"
                    name="radio-buttons"
                    size="small"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <h5 className="m-0">Old Parent Info</h5>
                </Row>
                <Row className="p-1">
                  <Col>Full Name : {oldParentInfo?.parentName}</Col>
                </Row>
                <Row className="p-1">
                  <Col>Email Address : {oldParentInfo?.parentEmailAddress}</Col>
                </Row>
                <Row className="p-1">
                  <Col>Phone Number : {oldParentInfo?.parentPhoneNumber}</Col>
                </Row>
                <Row className="p-1">
                  <Col>
                    Address :{" "}
                    {`${oldParentInfo?.address}, ${oldParentInfo?.state}, ${oldParentInfo?.city}, ${oldParentInfo?.zip}`}
                  </Col>
                </Row>
              </Col>
            )}
            <Col>
              <Row className="align-items-center">
                {oldParentInfo.address && (
                  <>
                    <Radio
                      checked={selectedValue === "new"}
                      onChange={handleChange}
                      value="new"
                      name="radio-buttons"
                      color="success"
                      inputProps={{ "aria-label": "B" }}
                      size="small"
                    />
                    <h5 className="m-0">New Parent Info</h5>
                  </>
                )}
              </Row>
              <Row className="p-1">
                <Col>Full Name : {newParentInfo.parentName || parentName}</Col>
              </Row>
              <Row className="p-1">
                <Col>
                  Email Address :{" "}
                  {newParentInfo.parentEmailAddress || parentEmailAddress}
                </Col>
              </Row>
              <Row className="p-1">
                <Col>
                  Phone Number :{" "}
                  {newParentInfo.parentPhoneNumber || parentPhoneNumber}
                </Col>
              </Row>
              <Row className="p-1">
                <Col>
                  Address :{" "}
                  {`${newParentInfo.address || address}, ${
                    newParentInfo.state || state
                  }, ${newParentInfo.city || city}, ${
                    newParentInfo.zip || zip
                  }`}
                </Col>
              </Row>
            </Col>
          </Col>
        </div>
        {totalStudents?.map((student, index) => {
          let {
            birthDate,
            fname,
            grade,
            lname,
            mname,
            fullSchoolName,
            due,
            enrollmentStatus,
            distanceFromSchool,
            registrationStatus,
          } = student;
          const tempDt = birthDate.split("-");
          birthDate = `${tempDt[1]}/${tempDt[2]}/${tempDt[0]}`;
          return (
            <div className="mb-4" key={index}>
              <div className={`p-2 ${style.heading}`}>
                {index === 0
                  ? "Student's Information"
                  : `Sibilng-${index++} Information`}
              </div>
              <div className={style.info}>
                {registrationStatus && (
                  <Alert severity="error">{`This student is already ${registrationStatus}. Please contact our main office if you think it is not correct. If you still wish to continue, you can choose one of the parent information mentioned above for current registration.`}</Alert>
                )}
                <Col className={`d-flex justify-content-between `}>
                  <Col>
                    <Row className="p-1">
                      <Col>
                        Full Name :{" "}
                        {`${fname} ${mname === null ? "" : mname} ${lname}`}
                      </Col>
                    </Row>
                    <Row className="p-1">
                      <Col>Date of Birth : {birthDate}</Col>
                    </Row>
                    <Row className="p-1">
                      <Col>School : {fullSchoolName}</Col>
                    </Row>
                    <Row className="p-1">
                      <Col>Grade : {grade}</Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row className="p-1">
                      <Col>Enrollment Status : {enrollmentStatus}</Col>
                    </Row>
                    <Row className="p-1">
                      <Col>
                        Distance from school : {distanceFromSchool} mile/s
                      </Col>
                    </Row>
                    <Row className="p-1">
                      <Col>Payment required : {due}</Col>
                    </Row>
                  </Col>
                </Col>
              </div>
            </div>
          );
        })}
        <Row className={`d-flex justify-content-around mb-4`}>
          <Button onClick={onHide}>Edit details</Button>
          <Button onClick={submitHandler}>Continue</Button>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ReviewInfo;
