import React from "react";
import { Spinner } from "react-bootstrap";
import style from "../css/Spinner.module.css";

const LoadingSpinner = () => (
  <div className={style.spinnerDiv}>
    <Spinner animation="border" role="status" className={style.spinner} />
    <h3 className="my-2">Loading...</h3>
  </div>
);

export default LoadingSpinner;
